import type { LinksFunction } from "@remix-run/node"
import type { UIMatch } from "@remix-run/react"

import * as Icon from "@iyk/icons"
import * as UI from "@iyk/ui"
import * as Sentry from "@sentry/remix"
import * as React from "react"
import * as Cart from "./lib/shopify/cart.server.ts"

import AppleTouchIconPngUrl from "@iyk/ui/assets/apple-touch-icon.png?url"
import FaviconSvgUrl from "@iyk/ui/assets/favicon.svg?url"
import styles from "./styles/style.css?url"

import { ClerkApp, ClerkErrorBoundary } from "@clerk/remix"
import { rootAuthLoader } from "@clerk/remix/ssr.server"
import { DateTime } from "@iyk/date-time"
import { FingerprintJSPro, FpjsProvider } from "@iyk/fingerprintjs-pro-react"
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  defer,
  isRouteErrorResponse,
  useLoaderData,
  useLocation,
  useMatches,
  useRouteError,
} from "@remix-run/react"
import { captureRemixErrorBoundaryError } from "@sentry/remix"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
import { WagmiProvider } from "wagmi"
import { z } from "zod"
import { CartProvider } from "./components/shop/cart-provider.tsx"
import { checkIYKUser } from "./lib/auth.server.ts"
import { publicEnv } from "./lib/env.server.ts"
import { getLang } from "./lib/lang/get-lang.server.ts"
import { useLang } from "./lib/lang/use-lang.ts"
import { mixpanelInit } from "./lib/mixpanel/mixpanel.client.ts"
import { LoginDialogProvider } from "./lib/ui/login-dialog-provider.tsx"
import { defineLoader } from "./lib/utils/define.ts"
import { wagmiConfig } from "./lib/utils/wagmi-config.ts"

export const links: LinksFunction = () => {
  return [
    { rel: "stylesheet", href: styles },
    { rel: "manifest", href: "/manifest.webmanifest" },
    {
      rel: "apple-touch-icon",
      sizes: "180x180",
      href: AppleTouchIconPngUrl,
    },
    {
      rel: "icon",
      type: "image/svg+xml",
      href: FaviconSvgUrl,
    },
  ]
}

export const loader = defineLoader((args) =>
  rootAuthLoader(args, async () => {
    const user = await checkIYKUser(args)
    const cart = Cart.get(args.request, user?.id)

    const lang = getLang(args.request.headers.get("Accept-Language"))

    DateTime.locale = lang.name

    return defer({
      ...publicEnv(),
      user,
      lang,
      cart,
    })
  }),
)

export type Loader = typeof loader

// #region Root

const queryClient = new QueryClient()

export default Sentry.withSentry(
  ClerkApp(() => {
    const lang = useLang()
    const data = useLoaderData<typeof loader>()
    const matches = useMatches()
    const theme = getTheme(matches)

    const classNameForHTML = UI.cx("text-gray-12 bg-background", theme)

    DateTime.locale = lang.name

    if (typeof window === "object") {
      mixpanelInit()
    }

    return (
      <html lang={data.lang.name} dir={data.lang.dir} className={classNameForHTML}>
        <head>
          <meta charSet="utf-8" />
          <meta name="viewport" content="width=device-width" />
          <Meta />
          <ClientPopoverScript />
          <ClientPublicEnv from={data.publicEnv} />
          <Links />
        </head>
        <body>
          <GoogleAnalytics id={data.publicEnv.PUBLIC_GA_TRACKING_ID} />
          <FpjsProvider
            loadOptions={{
              apiKey: data.publicEnv.FINGERPRINT_PUBLIC_KEY,
              endpoint: [
                "https://fp.iyk.app",
                FingerprintJSPro.defaultEndpoint, // The default endpoint as fallback
              ],
            }}
          >
            <WagmiProvider config={wagmiConfig}>
              <QueryClientProvider client={queryClient}>
                <LoginDialogProvider>
                  <CartProvider cart={data.cart}>
                    <Outlet />
                  </CartProvider>
                </LoginDialogProvider>
              </QueryClientProvider>
            </WagmiProvider>
          </FpjsProvider>

          <ScrollRestoration />
          <Scripts />
          <UI.Toaster />
        </body>
      </html>
    )
  }),
)

// #endregion

// #region Error Boundary

export const ErrorBoundary = ClerkErrorBoundary(() => {
  const error = useRouteError()

  if (!isRouteErrorResponse(error)) {
    captureRemixErrorBoundaryError(error)
  }

  return (
    <html lang="en">
      <head>
        <Meta />
        <Links />
      </head>
      <body>
        <ErrorBoundaryContent error={error} />
        <Scripts />
      </body>
    </html>
  )
})

function getErrorContent(error: unknown) {
  if (!isRouteErrorResponse(error)) {
    return {
      title: "Something went wrong",
      description: "Please try again or contact support if the problem persists.",
    }
  }

  switch (error.status) {
    case 400:
      return {
        title: "Bad request",
        description: "The request was invalid. Please try again with valid data.",
      }
    case 401:
      return {
        title: "Unauthorized",
        description: "You are not authorized to view this page.",
      }
    case 403:
      return {
        title: "Forbidden",
        description: "You do not have permission to view this page.",
      }
    case 404:
      return {
        title: "Page not found",
        description: "The page you were looking for could not be found.",
      }
    case 500:
      return {
        title: "Server error",
        description: "An unexpected error occurred.",
      }
    default:
      return {
        title: "Something went wrong",
        description: "Please try again or contact support if the problem persists.",
      }
  }
}

const ErrorBoundaryContent = ({ error }: ErrorBoundaryContentProps) => {
  const { title, description } = getErrorContent(error)

  return (
    <div className="p-4 h-svh flex flex-col">
      <div className="shrink-0">
        <Icon.IykLogo className="size-7" />
      </div>
      <div className="flex-1 flex flex-col items-center justify-center">
        <div className="text-center space-y-1">
          <UI.Title size="sm">{title}</UI.Title>
          <UI.Text size="sm" className="text-gray-11">
            {description}
          </UI.Text>
        </div>
      </div>
    </div>
  )
}

type ErrorBoundaryContentProps = { error: unknown }

// #endregion

// #region Theming

const themeSchema = z.union([z.literal("light"), z.literal("dark")])

type Theme = z.infer<typeof themeSchema>

// We need to find the last theme override in the matches array
function getTheme(matches: UIMatch[]): Theme {
  for (let i = matches.length - 1; i >= 0; i--) {
    const match = matches[i]
    const result = themeSchema.safeParse(Object(match.data as any).theme)

    if (result.success) {
      return result.data
    }
  }

  return "light"
}

// #endregion

// #region Components

// conditionally inject the popover polyfill for Firefox < 126
const ClientPopoverScript = () => (
  <script
    dangerouslySetInnerHTML={{
      __html: [
        `typeof HTMLElement !== "undefined"`,
        `typeof HTMLElement.prototype === "object"`,
        `!Reflect.has(HTMLElement.prototype, "popover")`,
        `document.write('<script src="https://cdn.jsdelivr.net/npm/@oddbird/popover-polyfill@latest/dist/popover.min.js"><'+'/script>')`,
      ].join("&&"),
    }}
  />
)

const ClientPublicEnv = ({ from }: { from: any }) => (
  <script
    dangerouslySetInnerHTML={{
      __html: `window.ENV=${JSON.stringify(from)}`,
    }}
  />
)

const GoogleAnalytics = ({ id }: { id?: string }) => {
  const location = useLocation()

  const pageview = (url: string, trackingId: string) => {
    if (!window.gtag) {
      console.warn(
        "window.gtag is not defined. This could mean your google analytics script has not loaded on the page yet.",
      )
      return
    }
    window.gtag("config", trackingId, {
      page_path: url,
    })
  }

  React.useEffect(() => {
    if (id) {
      pageview(location.pathname, id)
    }
  }, [location.pathname, id])

  if (!id) {
    return null
  }

  return (
    <>
      <script async src={`https://www.googletagmanager.com/gtag/js?id=${id}`} />
      <script
        async
        id="gtag-init"
        dangerouslySetInnerHTML={{
          __html: `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());

                gtag('config', '${id}', {
                  page_path: window.location.pathname,
                });
              `,
        }}
      />
    </>
  )
}

// #endregion

// #region Types

declare global {
  interface Window {
    gtag: (option: string, gaTrackingId: string, options: Record<string, unknown>) => void
  }
}

// #endregion
