import type { MotionProps } from "motion/react"

import * as React from "react"

import { cx } from "@iyk/ui"
import { motion } from "motion/react"
import { useCallbackRef } from "../hooks/use-callback-ref.ts"

// https://github.com/framer/motion/discussions/1884

interface AnimateHeightChange extends MotionProps {
  children: React.ReactNode
  className?: string
}

export const AnimateHeightChange = ({ children, className, ...props }: AnimateHeightChange) => {
  const [height, setHeight] = React.useState<number | "auto">("auto")

  const containerRef = useCallbackRef<HTMLDivElement>((node) => {
    const resizeObserver = new ResizeObserver(() => {
      const { height } = node.getBoundingClientRect()
      setHeight(height)
    })

    resizeObserver.observe(node)

    return () => {
      resizeObserver.unobserve(node)
      resizeObserver.disconnect()
    }
  })

  // In CSS, it's not possible to animate to or from a value of "auto".
  // Let's tell framer-motion to not animate at all in that case.
  const animate = height === "auto" ? false : { height }

  return (
    <motion.div
      className="overflow-hidden"
      animate={animate}
      transition={{ duration: 0.5, ease: [0.32, 0.72, 0, 1] }}
      initial={false}
      {...props}
    >
      <div ref={containerRef} className={cx("flex flex-col gap-2", className)}>
        {children}
      </div>
    </motion.div>
  )
}
