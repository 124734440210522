import * as Icon from "@iyk/icons"
import * as UI from "@iyk/ui"
import * as React from "react"

// #region Root

type CollapsibleContextValue = {
  isOpen: boolean
  toggle: () => void
  id: string
}

type RootProps = {
  defaultOpen?: boolean
  id: string
} & Omit<React.ComponentProps<"div">, "id">

const CollapsibleContext = React.createContext<CollapsibleContextValue | undefined>(undefined)

const Root = ({ id, defaultOpen = true, children, className, ...props }: RootProps) => {
  const [isOpen, setIsOpen] = React.useState(defaultOpen)

  const toggle = () => setIsOpen((prev) => !prev)

  const value = React.useMemo(() => ({ isOpen, toggle, id }), [isOpen, toggle, id])

  return (
    <CollapsibleContext.Provider value={value}>
      <div className={className} {...props}>
        {children}
      </div>
    </CollapsibleContext.Provider>
  )
}

const useCollapsibleContext = () => {
  const context = React.useContext(CollapsibleContext)
  if (!context) {
    throw new Error("useCollapsibleContext must be used within a Collapsible.Root")
  }
  return context
}

// #endregion

// #region Trigger

type TriggerProps = React.ComponentProps<"button">

const Trigger = ({ children, className, ...props }: TriggerProps) => {
  const { isOpen, toggle, id } = useCollapsibleContext()

  return (
    <button
      className={UI.cx("w-full flex items-center", className)}
      onClick={toggle}
      aria-expanded={isOpen}
      aria-controls={`${id}-content`}
      {...props}
    >
      {children}
      <Icon.ChevronDown
        data-open={isOpen}
        className="transition-transform data-[open=true]:rotate-180 data-[open=false]:rotate-0"
      />
    </button>
  )
}

// #endregion

// #region Content

type ContentProps = React.ComponentProps<"div">

const Content = ({ children, className, ...props }: ContentProps) => {
  const { isOpen, id } = useCollapsibleContext()

  return (
    <div
      id={`${id}-content`}
      className={UI.cx("data-[open=true]:h-auto data-[open=false]:h-0 overflow-hidden", className)}
      data-open={isOpen}
      aria-hidden={!isOpen}
      {...props}
    >
      {children}
    </div>
  )
}

// #endregion

// #region Export

export const Collapsible = { Root, Trigger, Content }

// #endregion
