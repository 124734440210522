import * as React from "react"

export function ModelViewer({
  loadingPlaceholder,
  className,
  width,
  height,
  ...props
}: ModelViewerProps) {
  const [isModelViewerLoaded, setIsModelViewerLoaded] = React.useState(false)

  React.useEffect(() => {
    import("@google/model-viewer").then(() => {
      setIsModelViewerLoaded(true)
    })
  })

  width = width ? (isNumberLike(width) ? width + "px" : String(width)) : "100%"
  height = height ? (isNumberLike(height) ? height + "px" : String(height)) : "100%"

  return (
    <span className={className}>
      {isModelViewerLoaded ? (
        <model-viewer
          style={{
            width,
            height,
            objectFit: "contain",
          }}
          {...props}
        />
      ) : (
        (loadingPlaceholder ?? null)
      )}
    </span>
  )
}

const isNumberLike = RegExp.prototype.test.bind(/^\d*\.?\d+$/) as (value: any) => boolean

export interface ModelViewerProps extends ModelViewerElement {
  className?: string
  loadingPlaceholder?: React.ReactNode
  width?: number | string
  height?: number | string
}

// The following interface is taken from: https://github.com/google/model-viewer/issues/1502#issuecomment-1207287775
interface ModelViewerElement
  extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>, "ref"> {
  /**
   * The URL to the 3D model. Only glTF/GLB models are supported.
   */
  src?: string

  "ios-src"?: string
  /**
   * Configures the model with custom text that will be used to describe the model to viewers who use a screen reader or otherwise
   * depend on additional semantic context to understand what they are viewing.
   */
  alt?: string
  /**
   * Displays an image instead of the model, useful for showing the user something before a model is loaded and ready to render.
   * If you use a poster with transparency, you may also want to set --poster-color to transparent so that the background shows through.
   */
  poster?: string
  /**
   * If you're using a seamless poster as generated by toBlob({idealAspect: true}) with --poster-color transparent (which is recommended),
   * then enable this attribute to turn off the poster's transition. This keeps the shadow from blinking, and the transition is no longer necessary anyway since the poster matches the rendering.
   */
  "seamless-poster"?: boolean | string
  /**
   * An enumerable attribute describing under what conditions the model should be preloaded. The supported values are "auto", "lazy" and "eager". Auto is equivalent to lazy, which loads the model
   * when it is near the viewport for reveal="auto", and when interacted with for reveal="interaction". Eager loads the model immediately.
   */
  loading?: "eager" | "lazy" | "auto"
  /**
   * This attribute controls when the model should be revealed. It currently supports three values: "auto", "interaction", and "manual".
   * If reveal is set to "interaction", <model-viewer> will wait until the user interacts with the poster before loading and revealing the model.
   * If reveal is set to "auto", the model will be revealed as soon as it is done loading and rendering. If reveal is set to "manual",
   * the model will remain hidden until dismissPoster() is called.
   *
   * @example: https://modelviewer.dev/examples/loading#preload
   */
  reveal?: "auto" | "interaction" | "manual"
  /**
   * This attribute makes the browser include credentials (cookies, authorization headers or TLS client certificates) in the request to fetch the 3D model. It's useful if the 3D model file is stored on another server that require authentication. By default the file will be fetch without credentials. Note that this has no effect if you are loading files locally or from the same domain.
   */
  "with-credentials"?: boolean
  /**
   * Controls the environmental reflection of the model. Normally if skybox-image is set, that image will also be used for the environment-image. Use environment-image to only set the reflection without affecting the background. If neither is specified, default lighting will be applied. If 'neutral' is specified without a skybox, then a more evenly-lit environment is applied instead.
   */
  "environment-image"?: string
  "camera-controls"?: boolean | string
  "disable-zoom"?: boolean | string
  "auto-rotate"?: boolean | string
  ar?: boolean | string
  autoplay?: boolean | string
  "shadow-intensity"?: number | string
  "ar-scale"?: number | string
  bounds?: string
  style?: React.CSSProperties
}

// https://github.com/DefinitelyTyped/DefinitelyTyped/discussions/71395#discussioncomment-11525908
declare module "react" {
  namespace JSX {
    interface IntrinsicElements {
      "model-viewer": ModelViewerElement
    }
  }
}
