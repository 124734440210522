import * as React from "react"
import { cva, type VariantProps } from "./cva.ts"

export const LinkButton = React.forwardRef<LinkButton.Element, LinkButton.Props>(
  ({ className, ...props }, ref) => (
    <button
      data-ui="LinkButton"
      type="button"
      className={classForLinkButton({ className })}
      ref={ref}
      {...props}
    />
  ),
)

export namespace LinkButton {
  export type Element = HTMLButtonElement

  export type Props = React.ComponentProps<"button"> & VariantProps<typeof classForLinkButton>
}

export const classForLinkButton = cva(
  [
    "inline-flex gap-2 items-center justify-center flex-grow-0",
    "text-sm/4",
    "text-gray-11",
    "underline underline-offset-4",

    // &:hover
    "hover:text-gray-12",

    // &:focus-visible
    "focus-visible:text-gray-12",

    // &:active
    "active:text-gray-11",

    // &:disabled
    "disabled:text-gray-8 disabled:pointer-events-none",
  ],
  {
    variants: {
      size: {
        sm: "py-1",
        md: "py-2",
      },
    },
    defaultVariants: {
      size: "md",
    },
  },
)
