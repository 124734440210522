import * as UI from "@iyk/ui"

export const ItemMedia = ({ className, kind = "ghost", ...props }: Props) => {
  return (
    <span className={classForContainer({ kind })}>
      <UI.Media {...props} className={UI.cx(classForItemMedia, className)} />
    </span>
  )
}

const classForItemMedia = UI.cx([
  "aspect-square object-contain rounded-none overflow-hidden bg-gray-2",
])

const classForContainer = UI.cva(
  [
    "relative",
    "after:absolute after:size-full after:inset-0",
    "after:z-10",
    "after:pointer-events-none",
    "after:border",
  ],
  {
    variants: {
      kind: {
        ghost: "after:border-transparent",
        outline: "after:border-[rgba(0,0,0,0.1)]",
      },
    },
  },
)

type Props = React.ComponentProps<typeof UI.Media> & {
  kind?: "ghost" | "outline"
}
