import type { action as addExistingWalletAction } from "../../routes/api.add-existing-wallet.ts"

import * as Icon from "@iyk/icons"
import * as UI from "@iyk/ui"
import * as React from "react"
import * as Dialog from "./dialog.tsx"

import { shortenHex } from "@iyk/evm-utils"
import { toSentenceCase, toTitleCase } from "@iyk/string"
import { useFetcher, useRevalidator } from "@remix-run/react"
import { AnimatePresence, motion } from "motion/react"
import { generateSiweNonce } from "viem/siwe"
import { useAccount, useConnect, useConnections, useDisconnect, useSignMessage } from "wagmi"
import { LoadersCache } from "../../cache/loaders-cache.ts"
import { IYK_SUPPORT_EMAIL } from "../constants.ts"
import { useIykUser } from "../hooks/use-iyk-user.ts"
import { useLang } from "../lang/use-lang.ts"
import { commonAnimationProps, fadeInOutVariant } from "../utils/framer-motion.ts"
import { getAddWalletSiweMessage } from "../utils/siwe.ts"
import { AnimateHeightChange } from "./animate-height-change.tsx"

type AddWalletDialogProps = {
  trigger?: React.ReactNode
  onWalletCreationComplete?: () => void
}

export function AddWalletDialog({ trigger, onWalletCreationComplete }: AddWalletDialogProps) {
  const lang = useLang()
  const { isConnected } = useAccount()
  const { disconnect } = useDisconnect()

  const [isDialogOpen, setIsDialogOpen] = React.useState(false)
  const [connectorStatus, setConnectorStatus] = React.useState<ConnectorStatus>("idle")
  const isConnectorPending = connectorStatus === "pending"

  const showChooseWallet = !isConnected
  const showConfirmWallet = isConnected

  return (
    <Dialog.Root open={isDialogOpen} onOpenChange={setIsDialogOpen}>
      <Dialog.Trigger asChild>
        {trigger ?? (
          <UI.Button variant="ghost">
            {toSentenceCase(lang.terms.ADD_EXISTING_WALLET)} <Icon.WalletConnect />
          </UI.Button>
        )}
      </Dialog.Trigger>

      <Dialog.Content
        className="flex flex-col gap-6 w-full"
        onInteractOutside={(e) => {
          // we want to prevent interaction outside when the connector is pending
          // which is most likely when Wallet Connect modal is open on top
          if (isConnectorPending) e.preventDefault()
        }}
      >
        {showConfirmWallet && (
          <UI.Button
            onClick={() => disconnect()}
            variant="ghost"
            size="sm"
            className="absolute left-1 top-1 md:left-4 md:top-4"
          >
            <Icon.ArrowLeft />
          </UI.Button>
        )}
        <AnimateHeightChange>
          <AnimatePresence mode="wait">
            {showChooseWallet && (
              <motion.div
                {...commonAnimationProps}
                key="choose-wallet"
                variants={fadeInOutVariant}
                transition={{ duration: 0.2, type: "spring", bounce: 0 }}
                className="flex flex-col gap-6"
              >
                <UI.Text>{toSentenceCase(lang.terms.USE_AN_EXISTING_WALLET)}</UI.Text>
                <ConnectorsList onConnectorStatusChange={setConnectorStatus} />
              </motion.div>
            )}
            {showConfirmWallet && (
              <motion.div
                {...commonAnimationProps}
                key="confirm-wallet"
                variants={fadeInOutVariant}
                exit={undefined}
                initial={{ y: 5, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                transition={{ duration: 0.15, ease: "easeOut" }}
              >
                <SignMessage
                  onWalletCreationComplete={() => {
                    setIsDialogOpen(false)
                    onWalletCreationComplete?.()
                  }}
                />
              </motion.div>
            )}
          </AnimatePresence>
        </AnimateHeightChange>
      </Dialog.Content>
    </Dialog.Root>
  )
}

// List of connectors that can be used to connect a wallet
export function ConnectorsList({
  onConnectorStatusChange,
}: {
  onConnectorStatusChange?: (status: ConnectorStatus) => void
}) {
  const lang = useLang()

  const { connectors, connect, status } = useConnect()
  const [selectedConnectorUID, setSelectedConnectorUID] = React.useState<string | undefined>()
  const isConnecting = status === "pending"

  React.useEffect(() => {
    onConnectorStatusChange?.(status)
  }, [status])

  return (
    <div className="flex flex-col gap-3">
      {connectors.map((connector) => {
        const isButtonLoading = isConnecting && connector.uid === selectedConnectorUID

        return (
          <UI.Button
            disabled={isConnecting}
            variant="outline"
            key={connector.uid}
            onClick={() => {
              setSelectedConnectorUID(connector.uid)
              connect({ connector })
            }}
          >
            {isButtonLoading && <Icon.Loading className="size-2" />}
            <img className="size-4" src={connector.icon} alt={connector.name} />
            {isButtonLoading ? lang.terms.CONNECTING : connector.name}
          </UI.Button>
        )
      })}
    </div>
  )
}

// Component used to sign a message to confirm the wallet
// As well as creating the wallet on IYK side
function SignMessage({
  onWalletCreationComplete,
}: {
  onWalletCreationComplete?: AddWalletDialogProps["onWalletCreationComplete"]
}) {
  const lang = useLang()
  const revalidator = useRevalidator()
  const addExistingWalletFetcher = useFetcher<typeof addExistingWalletAction>()
  const { isSignedIn, iykUser } = useIykUser()
  const { disconnect } = useDisconnect()
  const connections = useConnections()
  const { address } = useAccount()
  const connectionForAddress = address
    ? connections.find((connection) => connection.accounts.includes(address))
    : undefined
  // Confirm Wallet State
  const [isWaitingForSignature, setIsWaitingForSignature] = React.useState(false)
  const [siweNonce, setSiweNonce] = React.useState<string>("")
  const [message, setMessage] = React.useState<string>("")
  const [errorMessage, setErrorMessage] = React.useState<string>("")
  const { signMessage } = useSignMessage()

  const handleConfirm = async () => {
    setErrorMessage("")
    setIsWaitingForSignature(true)

    if (!isSignedIn) {
      alert("Please sign in with IYK")
    }

    signMessage(
      { message },
      {
        onSuccess: (signature) => {
          if (!address) {
            return setErrorMessage("Please reconnect wallet and try again.")
          }

          addExistingWalletFetcher.submit(
            {
              address,
              signature,
              nonce: siweNonce,
              setAsDefault: true,
            },
            {
              method: "POST",
              action: "/api/add-existing-wallet",
            },
          )
        },
        onError: (error) => {
          setIsWaitingForSignature(false)
          if (isWalletErrorCause(error.cause)) {
            setErrorMessage(`${error.cause.message} (${error.cause.code})`)
          } else {
            setErrorMessage(
              `Unknown error occurred, please reach out to ${IYK_SUPPORT_EMAIL} (${error.name})`,
            )
          }
        },
      },
    )
  }

  const clearSettingsLoaderCache = async () => {
    const cache = LoadersCache.singleton()
    await cache.settingsLoader.delete()
    revalidator.revalidate()
  }

  React.useEffect(() => {
    if (!addExistingWalletFetcher.data) {
      return
    }

    setIsWaitingForSignature(false)

    if ("error" in addExistingWalletFetcher.data) {
      setErrorMessage(addExistingWalletFetcher.data.error)
    }

    if (
      "status" in addExistingWalletFetcher.data &&
      addExistingWalletFetcher.data.status === "success"
    ) {
      clearSettingsLoaderCache()
      onWalletCreationComplete?.()
    }
  }, [addExistingWalletFetcher.data])

  React.useEffect(() => {
    if (address && iykUser?.email) {
      // TODO: The server should generate this nonce and store it in a cookie. The browser reads it from the cookie, and then sends the signed message to the server. The server should then read the nonce from the cookie and verify the signed message. This helps to prevent CSRF attacks where another site can construct a message signing with a nonce that they generate, and trick the user into signing it (and then make this request on their behalf).
      const nonce = generateSiweNonce()
      setSiweNonce(nonce)
      setMessage(
        getAddWalletSiweMessage({
          address,
          nonce,
          email: iykUser.email,
          uri: window.location.origin,
          domain: window.location.host,
        }),
      )
    }
  }, [address, isSignedIn])

  return (
    <div className="flex flex-col gap-4 text-center">
      <div className="flex flex-col gap-1">
        <UI.Text>{toTitleCase(lang.terms.SIGN_MESSAGE_TO_CONTINUE)}</UI.Text>
        <UI.Text size="xs" className="inline self-center bg-gray-2 p-0.5 text-gray-11">
          {shortenHex(address ?? "", {
            frontLength: 4,
            backLength: 4,
          })}
        </UI.Text>
      </div>
      {connectionForAddress?.connector?.icon && (
        <ConnectorLoadingIcon
          isLoading={isWaitingForSignature}
          icon={connectionForAddress.connector.icon}
          name={connectionForAddress.connector.name}
        />
      )}
      <div className="flex flex-col gap-2 w-full">
        <UI.Button
          onClick={handleConfirm}
          disabled={!message || isWaitingForSignature}
          className="w-full gap-2"
        >
          {isWaitingForSignature && <Icon.Loading className="size-2" />}
          {toTitleCase(lang.terms.SIGN_MESSAGE)}
        </UI.Button>
        {errorMessage && (
          <UI.Text size="xs" className="text-tomato-11">
            {errorMessage}
          </UI.Text>
        )}

        <UI.Button onClick={() => disconnect()} variant="ghost">
          {toSentenceCase(lang.terms.USE_ANOTHER_WALLET)}
        </UI.Button>
      </div>
    </div>
  )
}

// This is a loading icon that is shown when we're waiting for signature
function ConnectorLoadingIcon({
  icon,
  name,
  isLoading,
}: {
  icon: string
  name: string
  isLoading: boolean
}) {
  const pings = Array(3).fill(null) // we want to show 3 "pings" (circles)

  return (
    <UI.Stack className="justify-center py-4">
      {isLoading &&
        pings.map((_, index) => (
          <UI.Layer key={index}>
            <motion.div
              animate={{ opacity: [0, 1, 0], scale: [0, 1, 2] }}
              transition={{
                repeat: Infinity,
                duration: 1.3,
                ease: "linear",
                delay: index * 0.3,
              }}
              className="size-full rounded-full border border-gray-7"
            />
          </UI.Layer>
        ))}

      <UI.Layer className="z-10">
        <motion.img
          variants={connectorIconVariant}
          animate={isLoading ? "animate" : "initial"}
          className="size-7 object-contain will-change-transform"
          src={icon}
          alt={`${name} connector`}
        />
      </UI.Layer>
    </UI.Stack>
  )
}

type ConnectorStatus = "pending" | "success" | "error" | "idle"

interface WalletErrorCause {
  code: number
  message: string
}

function isWalletErrorCause(errorCause: unknown): errorCause is WalletErrorCause {
  if (!errorCause) return false
  return (
    typeof (errorCause as any)?.code === "number" &&
    typeof (errorCause as any)?.message === "string"
  )
}

const connectorIconVariant = {
  initial: { scale: 1 },
  animate: { scale: 0.85 },
}
