import type { PolymorphicComponent } from "./types.ts"

import * as React from "react"

import { cva, type VariantProps } from "./cva.ts"

export const Title = React.forwardRef(
  ({ size, className, as: Component = "h3", ...props }, ref) => {
    return <Component className={classForTitle({ size, className })} ref={ref} {...props} />
  },
) as PolymorphicComponent<"h3", VariantProps<typeof classForTitle>>

Title.displayName = "Title"

export const classForTitle = cva([], {
  variants: {
    size: {
      sm: "text-sm/4",
      base: "text-base/5",
      xl: "text-xl/6 tracking-[-0.0125rem]",
    },
  },
  defaultVariants: {
    size: "base",
  },
})

export const Text = React.forwardRef(({ size, className, as: Component = "p", ...props }, ref) => {
  return <Component className={classForText({ size, className })} ref={ref} {...props} />
}) as PolymorphicComponent<"p", VariantProps<typeof classForText>>

Text.displayName = "Text"

export const classForText = cva(["[&_[width='16']]:w-[1em] [&_[height='16']]:h-[1em]"], {
  variants: {
    size: {
      xs: "text-xs",
      "xs-upper": "text-xs uppercase tracking-[0.05rem]",
      sm: "text-sm",
      base: "text-base",
      lg: "text-lg/6",
      xl: "text-xl tracking-[-0.0125rem]",
    },
  },
  defaultVariants: {
    size: "base",
  },
})

export const TextButton = React.forwardRef(
  ({ className, as: Component = "button", ...props }, ref) => {
    return <Component className={classForTextButton({ className })} ref={ref} {...props} />
  },
) as PolymorphicComponent<"button", VariantProps<typeof classForTextButton>>

TextButton.displayName = "TextButton"

export const classForTextButton = cva([
  "inline-flex min-w-[1lh] min-h-[1lh] align-top",
  "gap-1 place-content-center place-items-center",
  "hover:bg-gray-3 hover:text-gray-12",
  "focus-visible:bg-gray-3 focus-visible:text-gray-12",
])
